










































































































































































import { ConfirmButton } from '@/app/components';
import { useFilters } from '@/app/composable';
import { WorkflowStatus } from '@/modules/apollo/constants';
import { AssetType, AssetTypeId } from '@/modules/asset/constants';
import AssetTypeIcon from '@/modules/asset/components/AssetTypeIcon.vue';
import { AlertSourceType } from '../constants/alert.constants';
import {
    PencilAltIcon,
    ExclamationCircleIcon,
    TrashIcon,
    ShareIcon,
    BellIcon,
    MinusIcon,
} from '@vue-hero-icons/outline';
import { CalendarIcon, FireIcon, ExclamationIcon, CollectionIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent } from '@vue/composition-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(weekOfYear);
dayjs.extend(utc);

export default defineComponent({
    name: 'AlertOverview',
    props: {
        alert: {
            type: Object,
            required: true,
        },
    },
    components: {
        BellIcon,
        PencilAltIcon,
        TrashIcon,
        CalendarIcon,
        AssetTypeIcon,
        ShareIcon,
        ExclamationCircleIcon,
        ConfirmButton,
        FireIcon,
        ExclamationIcon,
        CollectionIcon,
        MinusIcon,
    },
    setup(props: any) {
        const assetClass = (type: string) => {
            switch (type) {
                case AssetType.Result:
                    return 'bg-indigo-100 text-indigo-600';
                default:
                    return 'bg-orange-100 text-orange-600';
            }
        };

        const { formatDate, formatDateTime } = useFilters();

        const pipeline = computed(() =>
            props.alert?.entities.find(
                (entity: { id: string | number; name: string; type: AlertSourceType; mine: boolean }) =>
                    [AlertSourceType.Analytics, AlertSourceType.DataCheckin].includes(entity.type),
            ),
        );

        const assets = computed(() =>
            props.alert?.entities?.filter(
                (entity: { id: string | number; name: string; type: AlertSourceType; mine: boolean }) =>
                    [AlertSourceType.Dataset, AlertSourceType.Result].includes(entity.type),
            ),
        );

        return {
            WorkflowStatus,
            formatDate,
            formatDateTime,
            dayjs,
            AssetTypeId,
            assetClass,
            pipeline,
            assets,
        };
    },
});
